import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "h-full" }
const _hoisted_2 = {
  key: 0,
  class: "flex w-full h-full items-center justify-center"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageLoading = _resolveComponent("PageLoading")!
  const _component_StudyGallery = _resolveComponent("StudyGallery")!
  const _component_Viewer = _resolveComponent("Viewer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.studiesLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_PageLoading)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_StudyGallery, {
              class: "m-12",
              signs: _ctx.signs,
              "series-load": _ctx.loadingSeries,
              studies: _ctx.sortedStudies,
              readonly: _ctx.readonly,
              onOpen: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openViewer($event)))
            }, null, 8, ["signs", "series-load", "studies", "readonly"]),
            (_ctx.showViewer)
              ? (_openBlock(), _createBlock(_component_Viewer, {
                  key: 0,
                  class: "fixed inset-0 z-40",
                  studies: _ctx.sortedStudies,
                  series: _ctx.series,
                  "series-id": _ctx.seriesId,
                  "study-id": _ctx.studyId,
                  "signs-list": _ctx.signsSuggestions,
                  patient: _ctx.patient,
                  signs: _ctx.signs,
                  anatomies: _ctx.anatomies,
                  "images-route": _ctx.imagesRoute,
                  tools: _ctx.tools,
                  readonly: _ctx.readonly,
                  "inadequate-options": _ctx.inadequateOptions,
                  onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeViewer())),
                  onAddSign: _cache[2] || (_cache[2] = ($event: any) => (_ctx.addSign($event))),
                  onRemoveSign: _cache[3] || (_cache[3] = ($event: any) => (_ctx.removeSign($event))),
                  onSearchSigns: _ctx.updateSearchDebounced,
                  onLoadNextSigns: _cache[4] || (_cache[4] = ($event: any) => (_ctx.loadNextSigns())),
                  onCreateAnnotation: _cache[5] || (_cache[5] = ($event: any) => (_ctx.createAnnotation($event))),
                  onUpdateAnnotation: _cache[6] || (_cache[6] = ($event: any) => (_ctx.updateAnnotation($event))),
                  onRemoveAnnotation: _cache[7] || (_cache[7] = ($event: any) => (_ctx.removeAnnotation($event))),
                  onUpdateProcessedImages: _ctx.updateProcessedImages,
                  onSetInadequate: _cache[8] || (_cache[8] = ($event: any) => (_ctx.setInadequate($event)))
                }, null, 8, ["studies", "series", "series-id", "study-id", "signs-list", "patient", "signs", "anatomies", "images-route", "tools", "readonly", "inadequate-options", "onSearchSigns", "onUpdateProcessedImages"]))
              : _createCommentVNode("", true)
          ])
        ]))
  ]))
}